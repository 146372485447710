import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Flex, Input, Spin, Typography } from 'antd';
import { Error } from 'assets/icons/Error';
import { Profile } from 'assets/icons/Profile';
import { useState } from 'react';
import { multisenderService } from 'services/multisender';
import useSWR from 'swr';
import { BatchItem } from '../BatchItem';
import styles from './styles.module.css';

export function HistoryView() {
  const [search, setSearch] = useState<string>('');
  const { data, isLoading, error } = useSWR(
    '/multisends/history',
    () => multisenderService.getMultisendsHistory(),
    {
      refreshInterval: 10000,
    }
  );

  const filteredData = data?.filter((el) =>
    el.name.toLowerCase().includes(search.toLowerCase())
  );

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        className={styles.containerPlaceholder}
      >
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        justify="center"
        align="center"
        vertical
        className={styles.containerPlaceholder}
      >
        <div className={styles.placeholderContent}>
          <Error />
          <Typography.Title level={1}>Something went wrong</Typography.Title>
          <Typography.Text>
            Multisender history can’t be loaded. Refresh the page.
          </Typography.Text>
        </div>
      </Flex>
    );
  }
  if (!data || data?.length <= 0) {
    return (
      <Flex
        justify="center"
        align="center"
        vertical
        className={styles.containerPlaceholder}
      >
        <div className={styles.placeholderContent}>
          <Profile />
          <Typography.Title level={1}>Brand new account</Typography.Title>
          <Typography.Text>
            Your account doesn’t have any Multisender batches yet. Please do
            some transactions
            <br /> for the records to appear on the screen.
          </Typography.Text>
        </div>
      </Flex>
    );
  }

  return (
    <>
      <Input
        size="large"
        placeholder="Search by name"
        prefix={<SearchOutlined />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />
      {filteredData?.map((el) => (
        <BatchItem key={el.id} data={el} />
      ))}
    </>
  );
}
