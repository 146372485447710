import { SVGAttributes } from "react";

export function ExternalIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M12.8797 10.8324H12.0391C11.9734 10.8324 11.9188 10.8871 11.9188 10.9527V11.9184H2.07969V2.07773H11.9203V3.04336C11.9203 3.10898 11.975 3.16367 12.0406 3.16367H12.8813C12.9469 3.16367 13.0016 3.11055 13.0016 3.04336V1.47773C13.0016 1.21211 12.7875 0.998047 12.5219 0.998047H1.47969C1.21406 0.998047 1 1.21211 1 1.47773V12.5184C1 12.784 1.21406 12.998 1.47969 12.998H12.5203C12.7859 12.998 13 12.784 13 12.5184V10.9527C13 10.8855 12.9453 10.8324 12.8797 10.8324ZM13.1703 6.89961L10.9531 5.14961C10.8703 5.08398 10.75 5.14336 10.75 5.24805V6.43555H5.84375C5.775 6.43555 5.71875 6.4918 5.71875 6.56055V7.43555C5.71875 7.5043 5.775 7.56055 5.84375 7.56055H10.75V8.74805C10.75 8.85274 10.8719 8.91211 10.9531 8.84649L13.1703 7.09649C13.1853 7.08479 13.1973 7.06985 13.2056 7.0528C13.214 7.03574 13.2183 7.01702 13.2183 6.99805C13.2183 6.97908 13.214 6.96035 13.2056 6.9433C13.1973 6.92624 13.1853 6.9113 13.1703 6.89961Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
}
