import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ProfileOutlined,
  SyncOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { App, Button, Flex, Table, Tag, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { GasOutlined } from 'assets/icons/GasOtlined';
import { TokenCell } from 'components/TokenCell';
import { useMemo, useState } from 'react';
import {
  BuilderViewVariant,
  MultisenderTabs,
  useMultisenderStore,
} from 'screens/Multisender/store';
import { WalletCell } from 'screens/Transactions/components/TransactionsTable/WalletCell';
import { addressBookService } from 'services/addressBook';
import { multisenderService } from 'services/multisender';
import useSWR from 'swr';
import {
  Multisend,
  MultisenderItemStatus,
  MultisenderKind,
  MultisenderState,
} from 'types/multisender';
import { formatDate } from 'utilities/date';
import { formatUSDValue } from 'utilities/number';
import { toCapital } from 'utilities/string';
import { formatAddress } from 'utilities/wallets';
import { formatUnits } from 'viem';
import { DeleteDialog } from './DeleteDialog';
import styles from './styles.module.css';
import { ExternalIcon } from 'assets/icons/ExternalIcon';

interface BatchItemProps {
  data: Multisend['multisend'];
  variant?: 'history' | 'template';
}

type TargetItem = Multisend['multisend']['targets'][0];

interface BatchItemRecord extends TargetItem {
  source: Multisend['multisend']['source'];
  token: Multisend['multisend']['currency'];
  chain: Multisend['multisend']['chain'];
  chain_icon_url: Multisend['multisend']['chain_icon_url'];
  created_at: Multisend['multisend']['created_at'];
}

const columns: ColumnProps<BatchItemRecord>[] = [
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 240,
    sorter: (a, b) => a.address.localeCompare(b.address),
    render: (value) => {
      if (value.user_wallet_name) {
        return <Tag color="volcano">{value.user_wallet_name}</Tag>;
      }

      return formatAddress(value.address);
    },
  },
  {
    title: 'Target',
    dataIndex: 'target',
    key: 'target',
    width: 240,
    sorter: (a, b) => a.address.localeCompare(b.address),
    render: (_, record) => <WalletCell value={record} />,
  },
  {
    title: 'Token',
    dataIndex: 'token',
    key: 'token',
    width: 240,
    sorter: (a, b) => a.token.symbol.localeCompare(b.token.symbol),
    render: (token, record) => {
      return (
        <TokenCell
          currency={token}
          chain={record.chain}
          chainIconUrl={record.chain_icon_url}
        />
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 240,
    sorter: (a, b) => parseFloat(a?.amount) - parseFloat(b?.amount),
    render: (value) => {
      return <Typography.Text type="danger">-{value}</Typography.Text>;
    },
  },
];

export function BatchItem({ data, variant = 'history' }: BatchItemProps) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const [isSavingAsTemplate, setIsSavingAsTemplate] = useState(false);
  const { notification } = App.useApp();
  const [setTab, setInitialBuilderValues, setBuilderViewVariant, multisenderKind] =
    useMultisenderStore((s) => [
      s.setTab,
      s.setInitialBuilderValues,
      s.setBuilderViewVariant,
      s.multisenderKind,
    ]);

  const { data: wallets } = useSWR(
    '/counterparties_wallets',
    () => addressBookService.getWallets(),
    { revalidateOnFocus: false }
  );
  const { data: gasEstimation } = useSWR(
    '/multisend/gas',
    () => multisenderService.getGasEstimations(),
    {
      revalidateOnFocus: false,
    }
  );

  const selectedTokenPlatform = useMemo(() => {
    return data.currency.platforms.find(
      (platform) => platform.chain === data.chain
    );
  }, [data.currency.platforms, data.chain]);

  const totalSum = useMemo(() => {
    return data.targets.reduce(
      (acc, el) => acc + parseFloat(el?.amount ?? '0'),
      0
    );
  }, [data.targets]);

  const gas = useMemo(() => {
    if (!gasEstimation) return 0;

    let gas = gasEstimation[data.chain];
    if (!gas) return 0;
    return +formatUnits(
      BigInt(
        gas.approve_fee +
          gas.contract_interaction_fee +
          gas.transfer_fee * (data.targets?.length ?? 0)
      ),
      gas.currency_decimal
    );
  }, [gasEstimation, data.targets?.length, data.chain]);

  const downloadCSV = async () => {
    setIsCSVDownloading(true);
    try {
      await multisenderService.downloadMultisendAsCSV(data?.id, data.name);
    } catch (error) {
      notification.error({
        message: 'CSV download error',
        description: 'Try again later',
        placement: 'bottom',
      });
    } finally {
      setIsCSVDownloading(false);
    }
  };

  const saveAsTemplate = async () => {
    setIsSavingAsTemplate(true);
    try {
      await multisenderService.createMultisend({
        name: data.name,
        description: data.description ?? '',
        currency_id: data.currency?.id,
        kind: data.kind,
        chain: data.chain,
        state: MultisenderState.Template,
        source: data.source.address,
        targets: data.targets.map((el) => ({
          address: el.address,
          amount: parseFloat(el?.amount ?? 0),
        })),
      });

      notification.success({
        message: 'Template saved',
        description: `${data.name} saved as a template`,
        placement: 'bottom',
      });
    } catch (error) {
      notification.error({
        message: 'Template save error',
        description: 'Try again later',
        placement: 'bottom',
      });
    } finally {
      setIsSavingAsTemplate(false);
    }
  };

  const handleReuse = () => {
    setInitialBuilderValues({
      id: data.id,
      name: data.name,
      description: data.description ?? '',
      network: data.network,
      kind: data.kind,
      token: `${data.currency.id}-${data.chain}`,
      recipients: data.targets.map((el, i) => ({
        id: crypto.randomUUID(),
        no: i + 1,
        counterparty:
          el.counterparty_id ??
          wallets?.find((w) => w.wallet.address === el.address)
            ?.counterparty_id ??
          undefined,
        walletAddress: [el.address],
        amount: parseFloat(el?.amount ?? 0),
      })),
    });
    setTab(MultisenderTabs.Builder);
  };

  const [tagIcon, tagColor] = useMemo(() => {
    let tagIcon = undefined;
    let tagColor = undefined;

    switch (data.state) {
      case MultisenderState.New:
      case MultisenderState.InProgress:
      case MultisenderState.Processing:
        tagIcon = <SyncOutlined />;
        tagColor = 'blue';
        break;
      case MultisenderState.Confirmed:
        tagIcon = <CheckCircleOutlined />;
        tagColor = 'green';
        break;
      case MultisenderState.Failed:
        tagIcon = <CloseCircleOutlined />;
        tagColor = 'red';
        break;
    }

    return [tagIcon, tagColor];
  }, [data.state]);

  const historyColumns: ColumnProps<BatchItemRecord>[] = useMemo(() => {
    const historyColumns: ColumnProps<BatchItemRecord>[] = [
      ...columns,
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 240,
        sorter: (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        render: (value) => {
          return formatDate(value);
        },
      },
    ];

    if (data.seqsender_meta) {
      historyColumns.push({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 240,
        align: 'right',
        render: (value, record) => {
          if (value === MultisenderItemStatus.Done) {
            return (
              <Flex align="center" gap={8} justify="right">
                <Typography.Link
                  style={{ textDecoration: 'underline' }}
                  href={record.tx_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {toCapital(value ?? 'done')}
                </Typography.Link>
                <ExternalIcon />
              </Flex>
            );
          }

          return (
            <Flex gap={8} align="center" justify="right">
              <span className={styles.bathItemStatusPoint}></span>
              <Typography.Text>
                {toCapital(
                  value === MultisenderItemStatus.Processing ? 'Pending' : 'New'
                )}
              </Typography.Text>
            </Flex>
          );
        },
      });
    }

    return historyColumns;
  }, [data.seqsender_meta]);

  return (
    <Flex vertical gap={20}>
      <Flex align="center" gap={16}>
        <Flex align="center" gap={20}>
          <Typography.Title level={3} style={{ margin: 0 }}>
            {data.name}
          </Typography.Title>
          {variant === 'history' && (
            <Tag icon={tagIcon} color={tagColor}>
              {toCapital(data.state.replace('_', ' '))}
            </Tag>
          )}
        </Flex>
        <Flex align="center" gap={16} style={{ marginLeft: 'auto' }}>
          {variant === 'history' && (
            <>
              {!!data.tx_url && multisenderKind !== MultisenderKind.Sequential && (
                <Button
                  href={data.tx_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Block Explorer
                </Button>
              )}
              <Button
                icon={<DownloadOutlined />}
                loading={isCSVDownloading}
                onClick={downloadCSV}
              >
                .csv
              </Button>
              <Button icon={<SyncOutlined />} onClick={handleReuse}>
                Reuse
              </Button>
              <Button
                icon={<ProfileOutlined />}
                loading={isSavingAsTemplate}
                onClick={saveAsTemplate}
              >
                Save as template
              </Button>
            </>
          )}
          {variant === 'template' && (
            <>
              <Button
                icon={<DeleteOutlined />}
                aria-label="Delete template"
                onClick={() => setIsDeleteOpen(true)}
              />
              <Button
                icon={<EditOutlined />}
                aria-label="Edit template"
                onClick={() => {
                  handleReuse();
                  setBuilderViewVariant(BuilderViewVariant.Template);
                }}
              />
              <Button icon={<SyncOutlined />} onClick={handleReuse}>
                Use in Builder
              </Button>
              <DeleteDialog
                isOpen={isDeleteOpen}
                onOpenChange={setIsDeleteOpen}
                data={data}
              />
            </>
          )}
        </Flex>
      </Flex>
      <div>
        <Table
          size="small"
          rowKey={() => crypto.randomUUID()}
          columns={variant === 'history' ? historyColumns : columns}
          dataSource={data.targets.map((el, index) => ({
            ...el,
            source: data.source,
            token: data.currency,
            created_at: data.created_at,
            chain: data.chain,
            chain_icon_url: data.chain_icon_url,
          }))}
          pagination={false}
        />
        <div className={styles.bathItemSummary}>
          <Typography.Text className={styles.bathItemSummaryLabel}>
            <ThunderboltOutlined />
            Multisend total
          </Typography.Text>
          <Typography.Text>
            <span
              dangerouslySetInnerHTML={{ __html: formatUSDValue(totalSum, '') }}
            />{' '}
            {data.currency.symbol.toUpperCase()}
          </Typography.Text>
          {variant === 'template' && (
            <>
              <Typography.Text className={styles.bathItemSummaryLabel}>
                <GasOutlined />
                Approx. multisend gas:
              </Typography.Text>
              <Typography.Text>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatUSDValue(
                      gas,
                      '',
                      selectedTokenPlatform?.decimals
                    ),
                  }}
                />{' '}
                {gasEstimation?.[data.chain].currency_symbol}
              </Typography.Text>
            </>
          )}
        </div>
      </div>
    </Flex>
  );
}
