import Bugsnag from '@bugsnag/js';
import {
  getGasPrice,
  getTransaction,
  readContract,
  sendTransaction,
  waitForTransactionReceipt,
  writeContract,
} from '@wagmi/core';
import { App } from 'antd';
import { wagmiConfig } from 'configs/wagmi';
import {
  ARBITRUM_MULTISENDER_ADDRESS,
  EVM_MULTISENDER_ABI,
  EVM_MULTISENDER_ADDRESS,
  EVM_MULTISENDER_CHAINS,
} from 'constants/multisender/evm';
import { TRON_MULTISENDER_ABI } from 'constants/multisender/tron';
import { useCallback } from 'react';
import {
  MultisenderTabs,
  useMultisenderStore,
} from 'screens/Multisender/store';
import { multisenderService } from 'services/multisender';
import { Currency } from 'types';
import { MultisenderKind, MultisenderState } from 'types/multisender';
import { toFixed } from 'utilities/number';
import {
  Address,
  erc20Abi,
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
  Transaction,
} from 'viem';
import { arbitrum, mainnet } from 'viem/chains';
import { useAccount, usePublicClient, useSwitchChain } from 'wagmi';
import { usdtABI } from '../../../constants/usdtABI';

const USDT_MAINNET_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7';

interface MultisendParams {
  multiSendParams: {
    name: string;
    currency_id: number;
    platform: string;
  };

  /**
   * Token address to send
   */
  token: Currency;

  /**
   * Token decimals
   */
  decimals: number;

  /**
   *  Recipients
   */
  recipients: {
    id: string;
    no?: number;
    counterparty?: string;
    walletAddress?: string[];
    amount?: number;
  }[];

  /**
   * Multisender kind
   */
  kind: MultisenderKind;
}

const ETH_TRANSACTION_VALUE = 250000000000001;

export function useEVMMultisend() {
  const { address, chainId } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const publicClient = usePublicClient();
  const { notification } = App.useApp();

  const checkChain = useCallback(
    async (targetChainId: number) => {
      if (chainId !== targetChainId) {
        await switchChainAsync({ chainId: +targetChainId });
      }
    },
    [chainId, switchChainAsync]
  );

  const checkAllowance = useCallback(
    async ({
      token,
      decimals,
    }: Pick<MultisendParams, 'token' | 'decimals'>) => {
      if (!address || !publicClient) return;

      const allowance = await readContract(wagmiConfig, {
        abi: erc20Abi,
        address: token.platform.contract_address as Address,
        functionName: 'allowance',
        args: [
          address,
          EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
        ],
        chainId: token.platform.chain === 'arbitrum' ? arbitrum.id : mainnet.id,
      });

      return +formatUnits(allowance, decimals);
    },
    [checkChain, address]
  );

  const handleApprove = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      if (!address || !publicClient) return;

      const totalSum = recipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      if (
        !EVM_MULTISENDER_ADDRESS[token.platform.chain] ||
        !EVM_MULTISENDER_CHAINS[token.platform.chain]
      ) {
        throw new Error('Multisender address is not found');
      }
      const isUSDTMainnet = token.platform.contract_address.toLowerCase() === USDT_MAINNET_ADDRESS.toLowerCase() && token.platform.chain === 'mainnet';

      await checkChain(EVM_MULTISENDER_CHAINS[token.platform.chain]);
      const allowance = await readContract(wagmiConfig, {
        abi: erc20Abi,
        address: token.platform.contract_address as Address,
        functionName: 'allowance',
        args: [
          address,
          EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
        ],
      });
      if (allowance < parseUnits(totalSum.toString(), decimals)) {
        const gas = await publicClient.estimateContractGas({
          abi: isUSDTMainnet ? usdtABI : erc20Abi,
          address: token.platform.contract_address as Address,
          functionName: 'approve',
          args: [
            EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
            parseUnits(totalSum.toString(), decimals),
          ],
          account: address,
        });
        
        const allowanceTx = await writeContract(wagmiConfig, {
          abi: isUSDTMainnet? usdtABI : erc20Abi,
          address: token.platform.contract_address as Address,
          functionName: 'approve',
          args: [
            EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
            parseUnits(totalSum.toString(), decimals),
          ],
          gas: gas * BigInt(2),
        });

        await waitForTransactionReceipt(wagmiConfig, {
          hash: allowanceTx,
        });
      }
      return +formatUnits(allowance, decimals);
    },
    [checkChain, address]
  );

  const handleSubmit = useCallback(
    async ({
      multiSendParams,
      token,
      decimals,
      recipients,
      kind,
    }: MultisendParams) => {
      if (!address || !publicClient) return;

      if (
        !EVM_MULTISENDER_ADDRESS[token.platform.chain] ||
        !EVM_MULTISENDER_CHAINS[token.platform.chain]
      ) {
        throw new Error('Multisender address is not found');
      }

      const totalSum = recipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      const addresses = recipients.map(
        (recipient) => recipient.walletAddress?.[0] ?? ''
      );

      const values = recipients.map((recipient) =>
        parseUnits((recipient.amount ?? 0).toString(), decimals)
      );

      const targets = recipients.map((recipient) => ({
        address: recipient.walletAddress?.[0] ?? '',
        amount: +(recipient.amount ?? 0),
      }));

      let gas_calculated;
      let tx_hash;
      let multisend;

      try {
        await checkChain(EVM_MULTISENDER_CHAINS[token.platform.chain]);

        if (kind !== MultisenderKind.Sequential) {
          await handleApprove({
            token,
            decimals,
            recipients,
          });
        }

        if (kind === MultisenderKind.Sequential) {
          multisend = await multisenderService.createMultisend({
            name: multiSendParams.name,
            description: '',
            currency_id: multiSendParams.currency_id,
            chain: multiSendParams.platform,
            state: MultisenderState.New,
            source: address ?? '',
            targets: targets,
            kind: kind,
          });

          if (!multisend?.multisend.seqsender_meta) {
            throw new Error('Sequential multisend meta is missing');
          }

          const isUSDTMainnet = token.platform.contract_address.toLowerCase() === USDT_MAINNET_ADDRESS.toLowerCase() && token.platform.chain === 'mainnet';

          const tx = await writeContract(wagmiConfig, {
            abi: isUSDTMainnet ? usdtABI : erc20Abi,
            address: token.platform.contract_address as Address,
            functionName: 'transfer',
            args: [
              multisend?.multisend.seqsender_meta?.address as Address,
              BigInt(multisend.multisend.seqsender_meta.amount?.toString()),
            ],
            account: address,
          });
          tx_hash = tx;
          
          // const feeTx = await sendTransaction(wagmiConfig, {
          //   to: multisend?.multisend.seqsender_meta?.address as Address,
          //   value: parseEther(
          //     multisend?.multisend.seqsender_meta?.fee?.toString()
          //   ),
          // });

          await waitForTransactionReceipt(wagmiConfig, {
            hash: tx_hash,
          });
          // await waitForTransactionReceipt(wagmiConfig, {
          //   hash: feeTx,
          // });

          const { setTab } = useMultisenderStore.getState();
          setTab(MultisenderTabs.History);
        } else {
          const gas = await publicClient.estimateContractGas({
            abi: EVM_MULTISENDER_ABI,
            address: EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
            functionName: 'sendToMany',
            args: [addresses, values, token.platform.contract_address],
            account: address,
            value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
          });

          gas_calculated = +formatEther(gas);

          const tx = await writeContract(wagmiConfig, {
            abi: EVM_MULTISENDER_ABI,
            address: EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
            functionName: 'sendToMany',
            args: [addresses, values, token.platform.contract_address],
            gas,
            value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
          });

          tx_hash = tx;

          multisend = await multisenderService.createMultisend({
            name: multiSendParams.name,
            description: '',
            currency_id: multiSendParams.currency_id,
            chain: multiSendParams.platform,
            state: MultisenderState.InProgress,
            source: address ?? '',
            targets: targets,
            kind: kind ?? MultisenderKind.Fast,
          });
        }

        notification.success({
          message: 'Multisend in progress',
          description: 'Tokens are being sent',
          placement: 'bottom',
          duration: 15,
        });

        if (kind !== MultisenderKind.Sequential) {
          await waitForTransactionReceipt(wagmiConfig, {
            hash: tx_hash,
          });

          if (multisend) {
            await multisenderService.confirmMultisend(
              multisend.multisend,
              tx_hash,
              kind
            );
          }
        }

        return tx_hash;
      } catch (error) {
        if (multisend) {
          await multisenderService.editMultisend(
            {
              name: multisend.multisend.name,
              description: multisend.multisend.description ?? '',
              currency_id: multisend.multisend.currency.id,
              chain: multisend.multisend.chain,
              source: multisend.multisend.source.address,
              kind: multisend.multisend.kind,
              targets: multisend.multisend.targets.map((target) => ({
                address: target.address,
                amount: +target.amount,
              })),
              state: MultisenderState.Failed,
            },
            multisend.multisend.id
          );
        }
        if (tx_hash) {
          let transaction: Transaction | undefined;
          try {
            transaction = await getTransaction(wagmiConfig, {
              hash: tx_hash,
            });
          } catch (error) { }

          const errorEvent = {
            exception: (error as Error)?.message,
            native_token_symbol: 'ETH',
            native_token_calculated: +formatEther(
              BigInt(ETH_TRANSACTION_VALUE * addresses.length)
            ),
            native_token_actual: transaction?.value ?? 0,
            gas_calculated,
            gas_actual: transaction?.gas ?? 0,
            token_address: token,
            token_calculated: totalSum,
            tx_hash: tx_hash,
          };
          if (error instanceof Error) {
            Bugsnag.notify(error, (event) => {
              event.addMetadata('multisend', errorEvent);
            });
          }
        }
        throw error;
      }
    },
    [address, checkChain, handleApprove]
  );

  const estimateGas = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      try {
        if (!publicClient || !address) return 0;

        const addresses = recipients
          .map((recipient) => recipient.walletAddress?.[0] ?? '')
          .filter((address) => !!address);

        if (addresses.length === 0) {
          return 0;
        }

        const values = recipients.map((recipient) =>
          parseInt(((recipient.amount ?? 0) * 10 ** decimals).toString())
        );

        const gasEstimate = await publicClient.estimateContractGas({
          abi: TRON_MULTISENDER_ABI,
          address: ARBITRUM_MULTISENDER_ADDRESS as Address,
          functionName: 'sendToMany',
          args: [addresses, values, token],
          account: address,
          value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
        });

        const gasPrice = await getGasPrice(wagmiConfig, {
          chainId: arbitrum.id,
        });

        const fee = BigInt(ETH_TRANSACTION_VALUE * addresses.length);

        return +formatEther(gasPrice * gasEstimate + fee);
      } catch (error) {
        return 0;
      }
    },
    [publicClient, address]
  );

  const estimateGasForApprove = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      if (
        !publicClient ||
        !address ||
        !recipients ||
        !EVM_MULTISENDER_ADDRESS[token.platform.chain]
      )
        return undefined;

      const filteredRecipients = recipients.filter(
        (recipient) =>
          recipient?.counterparty ||
          recipient?.walletAddress?.[0] ||
          recipient?.amount
      );

      const totalSum = filteredRecipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      if (totalSum <= 0) return undefined;

      const allowance = await readContract(wagmiConfig, {
        abi: erc20Abi,
        address: token.platform.contract_address as Address,
        functionName: 'allowance',
        args: [
          address,
          EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
        ],
      });

      if (allowance >= parseUnits(totalSum.toString(), decimals)) {
        return 0;
      }

      const gasEstimate = await publicClient.estimateContractGas({
        abi: erc20Abi,
        address: token.platform.contract_address as Address,
        functionName: 'approve',
        args: [
          EVM_MULTISENDER_ADDRESS[token.platform.chain] as Address,
          parseUnits(totalSum.toString(), decimals),
        ],
        account: address,
      });

      const gasPrice = await getGasPrice(wagmiConfig, {
        chainId: arbitrum.id,
      });

      return +(gasPrice * gasEstimate).toString();
    },
    [publicClient, address]
  );

  return {
    sendToMany: handleSubmit,
    estimateGas,
    estimateGasForApprove,
    approve: handleApprove,
    checkAllowance,
  };
}
