import { readContract } from '@wagmi/core';
import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { wagmiConfig } from 'configs/wagmi';
import { EVM_MULTISENDER_ADDRESS } from 'constants/multisender/evm';
import { TRON_MULTISENDER_ADDRESS } from 'constants/multisender/tron';
import { NetworkType } from 'libs/types';
import { Currency } from 'types';
import {
  CreateMultisend,
  GasEstimations,
  Multisend,
  MultisenderKind,
  MultisenderState,
} from 'types/multisender';
import { getAccessToken } from 'utilities/user';
import { Address, erc20Abi, formatUnits } from 'viem';
import { arbitrum } from 'viem/chains';
import { tronWeb } from './tronWebAdapter';

export class MultisenderService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  async getMultisendsHistory(): Promise<Multisend['multisend'][]> {
    const url = '/multisends';
    const { data } = await this._api.get<Multisend['multisend'][]>(url, {
      headers: {
        Authorization: getAccessToken(true),
        Accept: 'application/json',
      },
    });

    return data.filter(
      (el) =>
        el.state === MultisenderState.Confirmed ||
        el.state === MultisenderState.Failed ||
        el.state === MultisenderState.Processing ||
        el.state === MultisenderState.InProgress ||
        el.state === MultisenderState.New
    );
  }

  async getMultisendsTemplates(): Promise<Multisend['multisend'][]> {
    const url = '/multisends';
    const { data } = await this._api.get<Multisend['multisend'][]>(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data.filter((el) => el.state === MultisenderState.Template);
  }

  async downloadMultisendAsCSV(id: string, name?: string) {
    const url = `/multisends/${id}`;
    const { data } = await this._api.get<Blob>(url, {
      headers: {
        Authorization: getAccessToken(),
        Accept: 'text/csv',
      },
    });

    const urlObject = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `${name ?? Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async createMultisend({
    name,
    description,
    currency_id,
    chain,
    state,
    source,
    targets,
    tx_id,
    kind,
  }: CreateMultisend) {
    try {
      const url = '/multisends';
      const { data } = await this._api.post<Multisend>(
        url,
        {
          name,
          description,
          currency_id,
          chain,
          state,
          source,
          targets,
          tx_id,
          kind,
        },
        {
          headers: {
            Authorization: getAccessToken(true),
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async editMultisend(
    {
      name,
      description,
      currency_id,
      chain,
      state,
      source,
      targets,
      tx_id,
    }: CreateMultisend,
    id: Multisend['multisend']['id']
  ) {
    try {
      const url = `/multisends/${id}`;
      const { data } = await this._api.put(
        url,
        {
          name,
          description,
          currency_id,
          chain,
          state,
          source,
          targets,
          tx_id,
        },
        {
          headers: {
            Authorization: getAccessToken(true),
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async confirmMultisend(
    multisend: Multisend['multisend'],
    tx_id: string,
    kind: MultisenderKind
  ) {
    try {
      await this.editMultisend(
        {
          name: multisend.name,
          description: multisend.description ?? '',
          currency_id: multisend.currency.id,
          chain: multisend.chain,
          source: multisend.source.address,
          kind: multisend.kind,
          targets: multisend.targets.map((target) => ({
            address: target.address,
            amount: +target.amount,
          })),
          state:
            kind === MultisenderKind.Sequential
              ? MultisenderState.InProgress
              : MultisenderState.Confirmed,
          tx_id: tx_id,
        },
        multisend.id
      );
    } catch (error) {
      console.error(error);
    }
  }

  async deleteMultisend(id: string) {
    const url = `/multisends/${id}`;
    await this._api.delete(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });
  }

  async getGasEstimations() {
    const url = `/multisends/gas_estimations`;
    const { data } = await this._api.get<GasEstimations>(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data;
  }

  async getAllowance(
    networkType: NetworkType,
    token: string,
    currencies: Currency[],
    address: string
  ) {
    switch (networkType) {
      case NetworkType.Tron: {
        const selectedToken = currencies?.find(
          (c) => c?.id === token
        )?.platform;

        const tokenAddress = selectedToken?.contract_address;

        const decimals = selectedToken?.decimals ?? 6;

        const tokenContract = await tronWeb?.contract()?.at(tokenAddress);
        const allowance =
          (await tokenContract
            .allowance(address, TRON_MULTISENDER_ADDRESS)
            .call());
        return (+allowance - 2000000) / 10 ** decimals;
      }
      case NetworkType.EVM: {
        const selectedToken = currencies?.find(
          (c) => c?.id === token
        )?.platform;

        const tokenAddress = selectedToken?.contract_address;

        const decimals = selectedToken?.decimals ?? 6;

        if (!tokenAddress) {
          throw new Error('Token address is not found');
        }

        const allowance = await readContract(wagmiConfig, {
          abi: erc20Abi,
          address: tokenAddress as Address,
          functionName: 'allowance',
          args: [address as Address, EVM_MULTISENDER_ADDRESS[selectedToken.chain] as Address],
          chainId: arbitrum.id,
        });

        return +formatUnits(allowance, decimals);
      }
      default:
        throw new Error('Network not supported');
    }
  }
}

export const multisenderService = new MultisenderService();
