import { NetworkType } from "libs/types";

export type Stages = 'development' | 'production';

export enum Period {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
}

export enum TransactionType {
  Expense = 'expense',
  Income = 'income',
}

export enum TransactionTagged {
  Marked = 'marked',
  NotMarked = 'not_marked',
}

export interface Transaction {
  id: string;
  type: TransactionType;
  comment: string | null;
  source: {
    address: string;
    user_wallet_id: string | null;
    user_wallet_name: string | null;
    counterparty_wallet_id: string | null;
    counterparty_wallet_name: string | null;
    counterparty_id: string | null;
    counterparty_name: string | null;
  };
  target: {
    address: string;
    user_wallet_id: null;
    user_wallet_name: null;
    counterparty_wallet_id: string;
    counterparty_wallet_name: string;
    counterparty_id: string;
    counterparty_name: string;
  };
  currency: {
    id: number;
    name: string;
    symbol: string;
    logo_url: string;
  };
  amount: number;
  date: string;
  explorer_url: string;
  payments: {
    id: string;
    tag_id: string;
    usd_amount: number;
  }[];
  chain_icon_url: string;
}

export interface Tag {
  id: string;
  name: string;
}

export type PaymentCreateRequest =
  | {
      usd_amount: number;
      tag_name?: string;
      tag_id: string;
    }
  | {
      usd_amount: number;
      tag_name: string;
      tag_id?: string;
    };

export interface CurrencyResponse {
  id: number;
  name: string;
  symbol: string;
  logo_url: string;
  address: string;
  platforms: {
    chain: string;
    contract_address: string;
    decimals: number;
  }[];
}

export interface Currency {
  id: string;
  tokenId: number;
  name: string;
  symbol: string;
  logo_url: string;
  platform: {
    chain: string;
    contract_address: string;
    decimals: number;
  };
  network_type: NetworkType;
}

export interface TransactionRequestBody {
  walletIds: string[];
  type?: string;
  tagged?: string;
  dateFrom?: string;
  dateTo?: string;
  amountFrom?: number;
  amountTo?: number;
  currencyIds?: number[];
  tagIds?: string[];
}

export interface TransactionsPaginated {
  transfers: Transaction[];
  meta: {
    total: number;
  };
}

export enum ChainIds {
  Tron = '0x2b6653dc',
  EVM = 42161,
}
