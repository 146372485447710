import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { AMOUNT_OF_TRANSFERS_PER_PAGE } from 'constants/transfers';
import { NetworkType } from 'libs/types';
import { sortBy } from 'lodash';
import { currencyStore } from 'store/currencies';
import { tagsStore } from 'store/tags';
import {
  Currency,
  CurrencyResponse,
  PaymentCreateRequest,
  Tag,
  Transaction,
  TransactionRequestBody,
  TransactionsPaginated,
  TransactionTagged,
} from 'types';
import { toFixed } from 'utilities/number';
import { getAccessToken } from 'utilities/user';

export class TransactionService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Method to get transfers
   * @returns Array of Wallet objects
   * @throws Error if request fails
   */
  async getTransfers(
    {
      walletIds,
      type,
      tagged,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      currencyIds,
      tagIds,
    }: TransactionRequestBody,
    page: number = 1
  ): Promise<TransactionsPaginated> {
    const url = '/transfers';

    const { data } = await this._api.get<TransactionsPaginated>(url, {
      params: {
        type,
        tagged,
        date_from: dateFrom,
        date_to: dateTo,
        amount_from: amountFrom,
        amount_to: amountTo,
        wallet_addresses: walletIds,
        currency_ids: currencyIds,
        tag_ids: tagIds?.filter((tag) => tag !== TransactionTagged.NotMarked),
        limit: AMOUNT_OF_TRANSFERS_PER_PAGE,
        offset: (page - 1) * AMOUNT_OF_TRANSFERS_PER_PAGE,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'application/json',
      },
    });

    return data;
  }

  /**
   * Method to get transfers
   * @returns Array of Wallet objects
   * @throws Error if request fails
   */
  async getTransfersAsCSV(
    {
      walletIds,
      type,
      tagged,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      currencyIds,
      tagIds,
    }: TransactionRequestBody,
    page: number = 1
  ): Promise<void> {
    const url = '/transfers';

    const { data } = await this._api.get<Blob>(url, {
      params: {
        type,
        tagged,
        date_from: dateFrom,
        date_to: dateTo,
        amount_from: amountFrom,
        amount_to: amountTo,
        wallet_addresses: walletIds,
        currency_ids: currencyIds,
        tag_ids: tagIds,
        limit: AMOUNT_OF_TRANSFERS_PER_PAGE,
        offset: (page - 1) * AMOUNT_OF_TRANSFERS_PER_PAGE,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'text/csv',
      },
    });

    const urlObject = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `transactions-${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * Method to create payment
   * @param transactionId
   * @param comment
   * @param payments
   * @returns
   * @throws Error if validation fails
   */
  async createPayment(
    transaction: Transaction,
    comment: string,
    payments: PaymentCreateRequest[]
  ): Promise<Transaction | null> {
    if (payments?.length <= 0 || !payments) {
      throw new Error('Add at least one payment.');
    }

    const totalSum = toFixed(
      payments?.reduce((acc, payment) => acc + payment.usd_amount, 0),
      2
    );

    if (totalSum > transaction.amount) {
      throw new Error('You trying to add incorrect amount.');
    }

    const url = `/transfers/${transaction.id}/payments`;

    const { data } = await this._api.post(
      url,
      {
        comment,
        payments,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );

    await this.getTags();

    return data;
  }

  async updatePayment(
    transaction: Transaction,
    comment: string,
    payments: PaymentCreateRequest[]
  ) {
    if (payments?.length <= 0 || !payments) {
      throw new Error('Add at least one payment.');
    }

    const totalSum = toFixed(
      payments?.reduce((acc, payment) => acc + payment.usd_amount, 0),
      2
    );

    if (totalSum > transaction.amount) {
      throw new Error('You trying to add incorrect amount.');
    }

    const url = `/transfers/${transaction.id}/payments`;

    const { data } = await this._api.put(
      url,
      {
        comment,
        payments,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );

    await this.getTags();

    return data;
  }

  /**
   * Method to get tags
   * @returns Array of Tag objects
   */
  async getTags(): Promise<Tag[]> {
    const { setTags, setIsLoading } = tagsStore.getState();
    try {
      const url = '/tags';
      setIsLoading(true);

      const { data } = await this._api.get<Tag[]>(url, {
        headers: {
          Authorization: getAccessToken(),
        },
      });

      setTags(data);

      return data;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
    return [];
  }

  /**
   * Method to get currencies
   * @returns Array of Currency objects
   */
  async getCurrencies(): Promise<Currency[]> {
    const { setIsLoading, setCurrencies } = currencyStore.getState();
    try {
      const url = '/currencies';
      setIsLoading(true);

      const { data } = await this._api.get<CurrencyResponse[]>(url, {
        headers: {
          Authorization: getAccessToken(),
        },
      });

      // Desctructure the data by platforms and set it to the store
      const currencies: Currency[] = []

      for (const currency of data) {
        for (const platform of currency.platforms) {
          currencies.push({
            ...currency,
            id: `${currency.id}-${platform.chain}`,
            tokenId: currency.id,
            platform,
            network_type: platform.chain === 'tron' ? NetworkType.Tron : NetworkType.EVM,
          });
        }
      }

      setCurrencies(sortBy(currencies, el => el.platform.chain));

      return currencies;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
    return [];
  }
}

export const transactionService = new TransactionService();
