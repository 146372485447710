import { useCallback, useRef } from 'react';

export function useDebouncedCallback<T extends (...args: any) => ReturnType<T>>(
  func: T,
  wait: number
) {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: Parameters<typeof func>) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );
}
