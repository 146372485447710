import { CheckOutlined, InfoCircleFilled } from '@ant-design/icons';
import { App, Button, Flex, Form, Tooltip, Typography } from 'antd';
import { WizardArrowIcon } from 'assets/icons/WizardArrow';
import { useDebouncedCallback } from 'hooks/useDebunceCallback';
import { useEVMMultisend } from 'libs/evm/hooks/useMultisend';
import { useAccount } from 'libs/hooks/useAccount';
import { useTRONMultisend } from 'libs/tron/hooks/useMultisend';
import { NetworkType } from 'libs/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import { useMultisenderStore } from 'screens/Multisender/store';
import { multisenderService } from 'services/multisender';
import { useCurrencyStore } from 'store/currencies';
import { MultisenderKind } from 'types/multisender';
import { RawContractErrorType } from 'viem';
import { useShallow } from 'zustand/react/shallow';

interface MultisenderWizardProps {
  isSubmitting: boolean;
  isCompleted: boolean;
  onSubmit: () => void;
  onError: () => void;
}

enum MultisendWizardStep {
  Approve = 'Approve',
  Send = 'Send',
}

export function MultisenderWizard({
  isSubmitting,
  isCompleted,
  onSubmit,
  onError,
}: MultisenderWizardProps) {
  const lastAllowanceCheck = useRef<number>(0);
  const form = Form.useFormInstance<MultisenderForm>();
  const [multisenderKind] = useMultisenderStore(
    useShallow((s) => [s.multisenderKind])
  );

  const [step, setStep] = useState<MultisendWizardStep>(
    multisenderKind !== MultisenderKind.Sequential
      ? MultisendWizardStep.Approve
      : MultisendWizardStep.Send
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const debouncedCheckAllowance = useDebouncedCallback(() => {
    if (
      !networkType ||
      !address ||
      multisenderKind === MultisenderKind.Sequential
    )
      return;

    setIsAllowanceLoading(true);
    multisenderService
      .getAllowance(networkType, token, currencies, address)
      ?.then((allowance) => {
        if (!allowance || !totalSum || totalSum > allowance) {
          setStep(MultisendWizardStep.Approve);
        } else if (totalSum <= allowance && allowance > 0) {
          setStep(MultisendWizardStep.Send);
        }
      })
      .catch(() => {
        setStep(MultisendWizardStep.Approve);
      })
      .finally(() => {
        setIsAllowanceLoading(false);
        lastAllowanceCheck.current = Date.now();
      });
  }, 1000);

  useEffect(() => {
    if (multisenderKind === MultisenderKind.Sequential) {
      setStep(MultisendWizardStep.Send);
      debouncedCheckAllowance();
    }
  }, [debouncedCheckAllowance, multisenderKind]);

  const { approve: approveTRON } = useTRONMultisend();
  const { approve: approveEVM } = useEVMMultisend();
  const { networkType, address } = useAccount();
  const [currencies] = useCurrencyStore((s) => [s.currencies]);
  const { notification } = App.useApp();

  const recipients = Form.useWatch('recipients', form);
  const token = Form.useWatch('token', form);

  const totalSum = useMemo(
    () =>
      recipients?.reduce(
        (acc, recipient) => acc + +(recipient?.amount ?? 0),
        0
      ) ?? 0,
    [recipients]
  );


  useEffect(() => {
    if (
      !networkType ||
      !address ||
      !debouncedCheckAllowance ||
      multisenderKind === MultisenderKind.Sequential
    )
      return;

    debouncedCheckAllowance();
  }, [totalSum, token, address, currencies, networkType, multisenderKind, debouncedCheckAllowance]);

  const handleApprove = async () => {
    const values = form.getFieldsValue();

    const recipients = values.recipients.filter(
      (recipient) =>
        recipient?.counterparty ||
        recipient?.walletAddress?.[0] ||
        recipient?.amount
    );

    try {
      const token = currencies?.find((c) => c?.id === values.token);
      if (networkType !== token?.network_type) {
        notification.error({
          message: 'Wallet address doesn’t match ',
          description: 'Selected Source wallet doesn’t match with Token type',
          placement: 'bottom',
        });
        return;
      }
      await form.validateFields();
      if (recipients.length <= 0) {
        throw new Error('Recipients list is empty');
      }
    } catch (error) {
      onError();

      return;
    }

    setIsApproving(true);
    try {
      switch (networkType) {
        case NetworkType.Tron: {
          const selectedToken = currencies?.find(
            (c) => c?.id === values.token
          )?.platform;

          const tokenAddress = selectedToken?.contract_address;

          const decimals = selectedToken?.decimals ?? 6;

          if (!tokenAddress) {
            throw new Error('Token address is not found');
          }
          await approveTRON({
            token: tokenAddress,
            decimals,
            recipients: recipients,
          });
          break;
        }
        case NetworkType.EVM: {
          const selectedToken = currencies?.find((c) => c?.id === values.token);

          const decimals = selectedToken?.platform?.decimals ?? 6;

          if (!selectedToken?.platform.contract_address) {
            throw new Error('Token address is not found');
          }

          await approveEVM({
            token: selectedToken,
            decimals,
            recipients: recipients,
          });

          lastAllowanceCheck.current = Date.now();
          break;
        }
        default:
          throw new Error('Network not supported');
      }

      setStep(MultisendWizardStep.Send);
    } catch (error) {
      let parsedError;
      try {
        parsedError = JSON.parse(JSON.stringify(error as Error, null, 2));
      } catch (error) {}
      if (
        (parsedError &&
          (error as Error).message
            ?.toLowerCase()
            ?.includes('rejected the request')) ||
        (error as any)?.message?.toLowerCase()?.includes('declined by user')
      ) {
        return;
      }

      if ((error as any).error === 'CONTRACT_VALIDATE_ERROR') {
        notification.error({
          message: 'Error',
          description: (error as any).message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        if (networkType === NetworkType.EVM) {
          const parsedError = error as RawContractErrorType;
          notification.error({
            message: 'Error',
            description:
              parsedError?.shortMessage ||
              parsedError?.details ||
              parsedError.message,
            placement: 'bottom',
          });
          return;
        }
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      console.error(error);
    } finally {
      setIsApproving(false);
    }
  };

  return (
    <Flex style={{ marginLeft: 'auto' }} vertical gap={4}>
      <Flex align="center" justify="space-between" gap={16}>
        {multisenderKind !== MultisenderKind.Sequential && (
          <Typography.Text
            style={{
              color:
                step !== MultisendWizardStep.Approve
                  ? 'var(--ant-color-text-tertiary)'
                  : undefined,
            }}
          >
            {isApproving && '1. Sign in your Phone or Browser'}
            {!isApproving && '1. Approve spent'}
            {!isApproving && (
              <Tooltip
                title={
                  <>
                    Authorize on-chain smart
                    <br /> contract to perform assets
                    <br /> transfer
                  </>
                }
              >
                <InfoCircleFilled
                  width={16}
                  height={16}
                  style={{ marginLeft: 8 }}
                />
              </Tooltip>
            )}
          </Typography.Text>
        )}

        <Typography.Text
          style={{
            color:
              step !== MultisendWizardStep.Send
                ? 'var(--ant-color-text-tertiary)'
                : undefined,
          }}
        >
          {multisenderKind !== MultisenderKind.Sequential && '2. '}
          {multisenderKind === MultisenderKind.Sequential && '1. '}
          {isSubmitting && 'Sign in your Phone or Browser'}
          {!isSubmitting && 'Confirm Transaction'}
        </Typography.Text>
      </Flex>
      <Flex justify="end" align="center" gap={20}>
        {multisenderKind !== MultisenderKind.Sequential && (
          <>
            <Button
              type="primary"
              onClick={handleApprove}
              disabled={step !== MultisendWizardStep.Approve}
              loading={isApproving}
              icon={step !== MultisendWizardStep.Approve && <CheckOutlined />}
            >
              {step === MultisendWizardStep.Approve && (
                <>
                  {isApproving && 'Confirm in Wallet'}
                  {!isApproving && 'Approve'}
                </>
              )}
            </Button>
            <WizardArrowIcon />
          </>
        )}
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={step !== MultisendWizardStep.Send || isCompleted}
          loading={isSubmitting}
          icon={isCompleted && <CheckOutlined />}
          color={isCompleted ? 'success' : undefined}
          style={{
            background: isCompleted ? '#52C41A' : undefined,
            borderColor: isCompleted ? '#52C41A' : undefined,
            color: isCompleted ? '#fff' : undefined,
          }}
        >
          {isSubmitting && !isCompleted && 'Confirm in Wallet'}
          {!isSubmitting && !isCompleted && 'Send'}
          {isCompleted && 'Transaction sent'}
        </Button>
      </Flex>
    </Flex>
  );
}
