import Bugsnag from '@bugsnag/js';
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { App } from 'antd';
import { TRON_MULTISENDER_ADDRESS } from 'constants/multisender/tron';
import { MINUTE } from 'constants/time';
import { useCallback } from 'react';
import { multisenderService } from 'services/multisender';
import { tronWeb } from 'services/tronWebAdapter';
import useSWR from 'swr';
import { ChainIds } from 'types';
import { MultisenderKind, MultisenderState } from 'types/multisender';
import { toFixed } from 'utilities/number';
import { waitForTransaction } from '../waitForTransaction';
import {
  MultisenderTabs,
  useMultisenderStore,
} from 'screens/Multisender/store';

interface MultisendParams {
  multiSendParams: {
    name: string;
    currency_id: number;
    platform: string;
  };
  /**
   * Token address to send
   */
  token: string;

  /**
   * Token decimals
   */
  decimals: number;

  /**
   *  Recipients
   */
  recipients: {
    id: string;
    no?: number;
    counterparty?: string;
    walletAddress?: string[];
    amount?: number;
  }[];

  /**
   * Multisender kind
   */
  kind: MultisenderKind;
}

const TRX_TRANSACTION_VALUE = 5000001;

export function useTRONMultisend() {
  const { wallet, address, signTransaction } = useWallet();
  const { notification } = App.useApp();

  const { data: chainParams } = useSWR(
    'getChainParameters',
    async () => await tronWeb.trx.getChainParameters(),
    {
      revalidateOnFocus: false,
      refreshInterval: MINUTE,
    }
  );

  const checkChain = useCallback(async () => {
    if (!wallet?.adapter) return;

    if ('network' in wallet?.adapter) {
      const network = await (wallet?.adapter as any).network();

      if (network.chainId !== ChainIds.Tron) {
        await wallet.adapter.switchChain(ChainIds.Tron);
      }
    }
  }, [wallet?.adapter]);

  const handleApprove = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      if (!tronWeb || !wallet?.adapter || !address) return;

      tronWeb.setAddress(address);

      const totalSum = recipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals, true),
        0
      );

      const tokenContract = await tronWeb?.contract()?.at(token);
      const allowance = await tokenContract
        .allowance(address, TRON_MULTISENDER_ADDRESS)
        .call();
      let allowanceBigInt;
      if (typeof allowance === 'bigint') {
        allowanceBigInt = allowance;
      } else {
        allowanceBigInt = BigInt(allowance.remaining);
      }
      const totalSumWithDecimals =
      BigInt(parseInt((totalSum * 10 ** decimals).toString()) + 2000000);

      if (allowanceBigInt < totalSumWithDecimals) {
        const approvalTx =
          await tronWeb?.transactionBuilder?.triggerSmartContract(
            token,
            'approve(address,uint256)',
            {
              feeLimit: 15000 * 10 ** decimals,
              callValue: 0,
              tokenValue: 0,
              tokenId: 0,
              txLocal: true,
            },
            [
              {
                type: 'address',
                value: TRON_MULTISENDER_ADDRESS,
              },
              {
                type: 'uint256',
                value: totalSumWithDecimals,
              },
            ],
            address
          );

        const signedApproval = await signTransaction(approvalTx.transaction);

        const approvalRes = await tronWeb.trx.sendRawTransaction(
          signedApproval
        );

        if (
          approvalRes.code === 'CONTRACT_VALIDATE_ERROR' ||
          approvalRes.code === 'BANDWITH_ERROR'
        ) {
          throw new Error(tronWeb.toUtf8(approvalRes.message));
        }
      }
    },
    [address, wallet?.adapter]
  );

  const handleSubmit = useCallback(
    async ({
      multiSendParams,
      token,
      decimals,
      recipients,
      kind,
    }: MultisendParams) => {
      if (!tronWeb || !wallet?.adapter || !address) return;

      const totalSum = recipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      const addresses = recipients.map(
        (recipient) => recipient.walletAddress?.[0] ?? ''
      );

      const targets = recipients.map((recipient) => ({
        address: recipient.walletAddress?.[0] ?? '',
        amount: +(recipient.amount ?? 0),
      }));

      let tx_hash;
      let multisend;

      try {
        await checkChain();

        const totalSumWithDecimals = parseInt(
          (totalSum * 10 ** decimals).toString()
        );

        tronWeb.setAddress(address);

        const tokenContract = await tronWeb?.contract()?.at(token);

        const balance = await tokenContract.balanceOf(address).call();

        if (balance < totalSumWithDecimals) {
          throw new Error('Insufficient balance');
        }
        if (kind !== MultisenderKind.Sequential) {
          await handleApprove({
            token,
            decimals,
            recipients,
          });
        }
        
        if (kind === MultisenderKind.Sequential) {
          multisend = await multisenderService.createMultisend({
            name: multiSendParams.name,
            description: '',
            currency_id: multiSendParams.currency_id,
            chain: multiSendParams.platform,
            state: MultisenderState.New,
            source: address ?? '',
            targets: targets,
            kind: kind,
          });

          if (!multisend?.multisend.seqsender_meta) {
            throw new Error('Sequential multisend meta is missing');
          }

          const tx = await tronWeb?.transactionBuilder.triggerSmartContract(
            token,
            'transfer(address,uint256)',
            {
              txLocal: true,
            },
            [
              {
                type: 'address',
                value: multisend.multisend.seqsender_meta.address,
              },
              {
                type: 'uint256',
                value:
                  +(multisend?.multisend.seqsender_meta?.amount ?? 0)
              },
            ],
            address
          );

          const signedTx = await signTransaction(tx.transaction);

          if (!signedTx) {
            throw new Error('Transaction failed');
          }

          const txRes = await tronWeb.trx.sendRawTransaction(signedTx);

          if (txRes.code === 'CONTRACT_VALIDATE_ERROR') {
            throw new Error(tronWeb.toUtf8(txRes.message));
          }

          tx_hash = txRes.txid;

          // const feeTx = await tronWeb?.transactionBuilder.sendTrx(
          //   multisend.multisend.seqsender_meta.address,
          //   +(multisend?.multisend.seqsender_meta?.fee ?? 0),
          //   address
          // );

          // const signedFeeTx = await signTransaction(feeTx);

          // if (!signedFeeTx) {
          //   throw new Error('Transaction failed');
          // }

          // const txFeeRes = await tronWeb.trx.sendRawTransaction(signedFeeTx);

          // if (txFeeRes.code === 'CONTRACT_VALIDATE_ERROR') {
          //   throw new Error(tronWeb.toUtf8(txFeeRes.message));
          // }

          // await waitForTransaction(txFeeRes.txid);
          await waitForTransaction(tx_hash);

          if (multisend) {
            await multisenderService.confirmMultisend(
              multisend.multisend,
              tx_hash,
              kind
            );
          }

          const { setTab } = useMultisenderStore.getState();
          setTab(MultisenderTabs.History);
        } else {
          const tx = await tronWeb?.transactionBuilder.triggerSmartContract(
            TRON_MULTISENDER_ADDRESS,
            'sendToMany(address[],uint256[],address)',
            {
              feeLimit: 15000 * 10 ** decimals,
              tokenValue: 0,
              tokenId: 0,
              txLocal: true,
              callValue: TRX_TRANSACTION_VALUE * addresses.length,
            },
            [
              {
                type: 'address[]',
                value: addresses,
              },
              {
                type: 'uint256[]',
                value: recipients.map((recipient) =>
                  parseInt(
                    ((recipient.amount ?? 0) * 10 ** decimals).toString()
                  )
                ),
              },
              {
                type: 'address',
                value: token,
              },
            ],
            address
          );

          const signedTx = await signTransaction(tx.transaction);

          if (!signedTx) {
            throw new Error('Transaction failed');
          }

          const txRes = await tronWeb.trx.sendRawTransaction(signedTx);

          if (txRes.code === 'CONTRACT_VALIDATE_ERROR') {
            throw new Error(tronWeb.toUtf8(txRes.message));
          }

          tx_hash = txRes.txid;

          multisend = await multisenderService.createMultisend({
            name: multiSendParams.name,
            description: '',
            currency_id: multiSendParams.currency_id,
            chain: multiSendParams.platform,
            state: MultisenderState.InProgress,
            source: address ?? '',
            targets: targets,
            kind: kind ?? MultisenderKind.Fast,
          });
        }

        notification.success({
          message: 'Multisend in progress',
          description: 'Tokens are being sent',
          placement: 'bottom',
          duration: 15,
        });

        if (kind !== MultisenderKind.Sequential) {
          await waitForTransaction(tx_hash);

          if (multisend) {
            await multisenderService.confirmMultisend(
              multisend.multisend,
              tx_hash,
              kind
            );
          }
        }

        return tx_hash;
      } catch (error) {
        if (multisend) {
          await multisenderService.editMultisend(
            {
              name: multisend.multisend.name,
              description: multisend.multisend.description ?? '',
              currency_id: multisend.multisend.currency.id,
              chain: multisend.multisend.chain,
              source: multisend.multisend.source.address,
              kind: multisend.multisend.kind,
              targets: multisend.multisend.targets.map((target) => ({
                address: target.address,
                amount: +target.amount,
              })),
              state: MultisenderState.Failed,
            },
            multisend.multisend.id
          );
        }
        if (tx_hash) {
          let transaction: any | undefined;
          try {
            transaction = await tronWeb.trx.getTransactionInfo(tx_hash);
          } catch (error) {}

          const errorEvent = {
            exception: (error as Error)?.message,
            native_token_symbol: 'TRX',
            native_token_calculated:
              (TRX_TRANSACTION_VALUE * addresses.length) / 10 ** 6,
            native_token_actual: transaction?.value ?? 0,
            gas_calculated:
              addresses.length > 0 ? 25 + 25 * addresses.length : 0,
            gas_actual: transaction?.fee / 10 ** 6 ?? 0,
            token_address: token,
            token_calculated: totalSum,
            tx_hash: tx_hash,
          };

          if (error instanceof Error) {
            Bugsnag.notify(error, (event) => {
              event.addMetadata('multisend', errorEvent);
            });
          }
        }
        throw error;
      }
    },
    [address, signTransaction, wallet?.adapter, checkChain, handleApprove]
  );

  const estimateGas = useCallback(
    async ({
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      const filledRecipients = recipients.filter(
        (recipient) =>
          (recipient.walletAddress?.length ?? 0) > 0 || recipient.amount
      );

      return filledRecipients.length > 0
        ? 25 + 25 * filledRecipients.length
        : 0;
    },
    []
  );

  const estimateGasForApprove = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      if (!address || !recipients || !chainParams) return undefined;

      const filteredRecipients = recipients.filter(
        (recipient) =>
          recipient?.counterparty ||
          recipient?.walletAddress?.[0] ||
          recipient?.amount
      );

      const totalSum = filteredRecipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      if (totalSum <= 0) return undefined;

      const totalSumWithDecimals = parseInt(
        (totalSum * 10 ** decimals).toString()
      );

      const tokenContract = await tronWeb?.contract()?.at(token);

      const allowance = await tokenContract
        .allowance(address, TRON_MULTISENDER_ADDRESS)
        .call();
      let allowanceBigInt;
      if (typeof allowance === 'bigint') {
        allowanceBigInt = allowance;
      } else {
        allowanceBigInt = BigInt(allowance.remaining);
      }

      if (allowanceBigInt >= totalSumWithDecimals) {
        return 0;
      }

      const energyEstimate =
        await tronWeb.transactionBuilder.triggerConfirmedConstantContract(
          token,
          'approve(address,uint256)',
          {
            feeLimit: 15000 * 10 ** decimals,
            callValue: 0,
            tokenValue: 0,
            tokenId: 0,
            txLocal: true,
          },
          [
            {
              type: 'address',
              value: TRON_MULTISENDER_ADDRESS,
            },
            {
              type: 'uint256',
              value: totalSumWithDecimals,
            },
          ],
          address
        );

      const energyFee = chainParams?.filter(
        (item: any) => item.key === 'getEnergyFee'
      )[0].value;
      const feeLimit = energyEstimate.energy_used * energyFee;

      return feeLimit;
    },
    [address, chainParams]
  );

  return {
    sendToMany: handleSubmit,
    estimateGas,
    estimateGasForApprove,
    approve: handleApprove,
  };
}
